import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const TabArea = ({ items, activeIndex, clickHandler }) => {
  return (
    <div className="w-full flex flex-wrap justify-center gap-2.5 mb-2">
      {items.map((item, index) => (
        <span
          key={item.id}
          className={`block h5-subtext-bold py-2.5 px-5 rounded-[20px] transition-all duration-200 ease-linear cursor-pointer ${
            activeIndex === index + 1 ? 'bg-black-100 text-white' : 'text-black-100'
          }`}
          onClick={() => clickHandler(index + 1)}
        >
          {item.content}
        </span>
      ))}
    </div>
  )
}

const TabCover = ({ title = '', tabItems }) => {
  const [selectedOption, setSelectedOption] = useState(1)
  const handleOptionChange = (optionIndex) => setSelectedOption(optionIndex)

  const { picture, coverTitle, coverContent, cta, link, note } = tabItems[selectedOption - 1]

  return (
    <div className="w-full max-w-[800px] flex flex-col gap-10">
      {title && <h2 className="h2-block-title text-black-100 text-center">{title}</h2>}
      <div className="flex flex-col gap-5 items-center">
        {tabItems.length >= 2 && (
          <TabArea items={tabItems} clickHandler={handleOptionChange} activeIndex={selectedOption} />
        )}
        <div
          style={{ backgroundImage: `url(${picture})` }}
          className="w-full p-3 min-h-[400px] rounded-xl bg-no-repeat bg-cover bg-center overflow-hidden flex flex-col items-center justify-center gap-5 transition-all duration-500 ease-linear"
        >
          {coverTitle && <p className="h3-card-title text-white text-center">{coverTitle}</p>}
          {coverContent && <p className="h3-card-title text-white text-center">{coverContent}</p>}
          {link && cta && (
            <Link
              to={link}
              className="btn-cover-black px-[18px] py-3 rounded w-[80%] text-center md:w-fit h4-content-text"
            >
              {cta}
            </Link>
          )}
        </div>
        {note && <p className="h5-subtext-medium text-black-90 w-full">{note}</p>}
      </div>
    </div>
  )
}

TabArea.propTypes = {
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  clickHandler: PropTypes.func
}

TabCover.propTypes = {
  title: PropTypes.string,
  tabItems: PropTypes.array,
  children: PropTypes.element
}

export default TabCover
