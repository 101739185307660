import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { clickMessageBirdChat } from 'utils'

const Cover = ({
  smallTitle = '',
  title,
  imgSrc,
  imgAlt,
  paragraphContent,
  actionLinkOne,
  actionTextOne,
  actionLinkTwo,
  actionTextTwo
}) => {
  return (
    <div className="w-full h-full relative rounded-[60px] overflow-hidden">
      <img
        alt={imgAlt}
        src={imgSrc}
        className="absolute top-0 left-0 w-full h-full object-cover rounded-t-[64px] overflow-hidden"
      />
      <div className="absolute p-9 md:pt-[80px] md:pl-[60px] md:pr-[120px] xxl:pt-[120px] xxl:pl-[120px] xxl:pr-[240px] flex flex-col justify-center flex-wrap gap-[60px] w-full h-full md:h-auto">
        <div className="w-full">
          {smallTitle && <p className="h5-subtext-bold text-white mb-2 text-cover-shadow-sm">{smallTitle}</p>}
          <h1 className="h1-page-title-mobile md:h1-page-title text-white text-cover-shadow-lg">{title}</h1>
        </div>
        <p className="hidden md:block h4-content-text text-white text-cover-shadow-sm max-w-[600px]">
          {paragraphContent}
        </p>
        <div className="flex flex-col items-center md:items-start gap-5">
          {actionLinkOne && actionTextOne && actionLinkOne !== 'chatbot' ? (
            <Link
              to={actionLinkOne}
              className="btn-cover-orange block px-[18px] py-3 rounded w-full text-center md:text-left md:w-fit h4-content-text"
            >
              {actionTextOne}
            </Link>
          ) : (
            <button
              onClick={() => clickMessageBirdChat()}
              className="btn-cover-orange px-[18px] py-3 rounded w-full text-center md:text-left md:w-fit h4-content-text"
            >
              {actionTextOne}
            </button>
          )}
          {actionLinkTwo && actionTextTwo && (
            <Link
              to={actionLinkTwo}
              className="btn-cover-black block px-[18px] py-3 rounded w-full text-center md:text-left md:w-fit h4-content-text"
            >
              {actionTextTwo}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

Cover.propTypes = {
  smallTitle: PropTypes.string,
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  paragraphTitle: PropTypes.string,
  paragraphContent: PropTypes.string,
  actionLinkOne: PropTypes.string,
  actionTextOne: PropTypes.string,
  actionLinkTwo: PropTypes.string,
  actionTextTwo: PropTypes.string
}

export default Cover
