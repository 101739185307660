import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { clickMessageBirdChat } from 'utils'

const WideCard = ({
  title = '',
  imgSrc,
  imgAlt,
  mainParagraph = '',
  paragraphTitle = '',
  paragraphContent = '',
  actionLink,
  actionText,
  actionColor,
  children
}) => {
  return (
    <div className="w-full flex flex-col gap-10">
      {title && (
        <h2 className="whitespace-pre-wrap h2-block-title text-black-100 text-center">
          {title.split('/n/').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </h2>
      )}
      {mainParagraph && (
        <div className="w-full flex flex-col items-center justify-center ">
          <p className="h5-subtext-bold text-black-90 max-w-[800px] text-center">
            {mainParagraph.split('/n/').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
                <br />
              </React.Fragment>
            ))}
          </p>
        </div>
      )}
      <div className="relative flex w-full rounded-[20px] overflow-hidden justify-end">
        <img src={imgSrc} alt={imgAlt} className="hidden md:block absolute w-[40%] h-full object-cover top-0 left-0" />
        {children ? (
          children
        ) : (
          <div className="relative py-[60px] px-10 flex flex-col gap-10 w-full md:w-[60%] bg-experience-gradient">
            <div>
              {paragraphTitle && <p className="h3-card-title text-white">{paragraphTitle}</p>}
              {paragraphContent && <p className="h4-content-text text-white">{paragraphContent}</p>}
            </div>
            {actionLink.includes('https') ? (
              <a
                href={actionLink}
                target="_blank"
                rel="noreferrer noopenner"
                className={`${
                  actionColor ? actionColor : 'btn-white-shadow'
                } block h4-content-text-bold px-[18px] py-3 rounded w-fit`}
              >
                {actionText}
              </a>
            ) : actionLink === 'chatbot' ? (
              <button
                onClick={() => clickMessageBirdChat()}
                className={`${
                  actionColor ? actionColor : 'btn-white-shadow'
                }  h4-content-text-bold px-[18px] py-3 rounded w-fit`}
              >
                {actionText}
              </button>
            ) : (
              <Link
                to={actionLink}
                className={`${
                  actionColor ? actionColor : 'btn-white-shadow'
                }  h4-content-text-bold px-[18px] py-3 rounded w-fit`}
              >
                {actionText}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

WideCard.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  mainParagraph: PropTypes.string,
  paragraphTitle: PropTypes.string,
  paragraphContent: PropTypes.string,
  actionLink: PropTypes.string,
  actionText: PropTypes.string,
  actionColor: PropTypes.string,
  children: PropTypes.element
}

export default WideCard
