import React from 'react'
import carouselOne from '/static/images/use-case/by-scenario/video_on_demand_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-scenario/video_on_demand_feature_2.webp'
import carouselThree from '/static/images/use-case/by-scenario/video_on_demand_feature_3.webp'
import carouselFour from '/static/images/use-case/by-scenario/video_on_demand_feature_4.webp'
import tabOne from '/static/images/use-case/by-scenario/video_on_demand_scenario_multi_networks.webp'
import tabTwo from '/static/images/use-case/by-scenario/video_on_demand_scenario_fast_by_nature.webp'
import tabThree from '/static/images/use-case/by-scenario/video_on_demand_scenario_altering_networks.webp'
import tabFour from '/static/images/use-case/by-scenario/video_on_demand_scenario_visibility.webp'

import { clickMessageBirdChat } from 'utils'

export const videoTabs = [
  {
    id: 'multi-networks',
    content: 'Observability in Quality',
    picture: tabOne,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics analyzes video performance (buffering, quality, latency) to measure how well the viewer experience is working.`
  },
  {
    id: 'fastByNature',
    content: 'Fast by nature',
    picture: tabTwo,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics Video Stream supports low-latency protocols like LL-HLS and RTMP, ensuring less than two seconds of latency for a seamless viewing experience.`
  },
  {
    id: 'alteringNetworks',
    content: 'Altering networks',
    picture: tabThree,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics Video Stream’s multi-network approach ensures that delivery is always up.`
  },
  {
    id: 'visibility',
    content: 'Visibility',
    picture: tabFour,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics provides detailed analytics and real-time monitoring, allowing customers to manage their network efficiently and make cost-effective decisions.`
  }
]

export const videoFeatures = [
  {
    title: 'Poor quality and buffering',
    content: 'Viewers experience frustration with slow buffering and poor video quality, leading to high churn rates.',
    imageSrc: carouselOne,
    imageAlt: 'Poor quality and buffering'
  },
  {
    title: 'High latency',
    content: 'High latency disrupts real-time interactions and live streaming experiences.',
    imageSrc: carouselTwo,
    imageAlt: 'High latency'
  },
  {
    title: 'Single point of failure',
    content: 'Relying on a single network can lead to service disruptions if the provider experiences issues.',
    imageSrc: carouselThree,
    imageAlt: 'Single point of failure'
  },
  {
    title: 'Cost management',
    content: `Managing costs while maintaining high-quality streaming services is a significant challenge.`,
    imageSrc: carouselFour,
    imageAlt: 'Cost management',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  }
]
