import hyperSpeed from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_card.webp'
import voc from '/static/images/use-case/by-scenario/video_on_demand_card.webp'
import aigc from '/static/images/use-case/by-scenario/ai-generated_content_card.webp'
import liveStream from '/static/images/use-case/by-scenario/live_streaming_for_sports_card.webp'
import updateGames from '/static/images/use-case/by-scenario/updates_and_patches_for_games_card.webp'
import iot from '/static/images/use-case/by-industry/iot_device_manufacturer_card.webp'
import gaming from '/static/images/use-case/by-industry/gaming_card.webp'
import media from '/static/images/use-case/by-industry/media_card.webp'
import publisher from '/static/images/use-case/by-industry/publisher_card.webp'

export const cases = [
  {
    category: 'By Industry',
    picture: iot,
    purpose: 'IoT device manufacturer',
    url: '/use-case/iot-device-manufacturer'
  },
  {
    category: 'By Industry',
    picture: gaming,
    purpose: 'Gaming',
    url: '/use-case/gaming'
  },
  {
    category: 'By Industry',
    picture: media,
    purpose: 'Media',
    url: '/use-case/media'
  },
  {
    category: 'By Industry',
    picture: publisher,
    purpose: 'Publisher',
    url: '/use-case/publisher'
  },
  {
    category: 'By Scenario',
    picture: hyperSpeed,
    purpose: 'Hyper-speed files delivery',
    url: '/use-case/hyper-speed-files-delivery'
  },
  {
    category: 'By Scenario',
    picture: voc,
    purpose: 'Video on demand',
    url: '/use-case/video-on-demand'
  },
  {
    category: 'By Scenario',
    picture: aigc,
    purpose: 'AI generated content',
    url: '/use-case/ai-generated-content'
  },
  {
    category: 'By Scenario',
    picture: liveStream,
    purpose: 'Live streaming for sports',
    url: '/use-case/live-streaming-for-sports'
  }
  // {
  //   category: 'By Scenario',
  //   picture: updateGames,
  //   purpose: 'Updates and patches for games',
  //   url: '/use-case/updates-and-patches-for-games'
  // },
]
