import React, { useEffect, useState } from 'react'
import { cases } from '/static/data/useCase.static'
import Icon from 'components/icon/index'
import './swiper.css'
import { Link } from 'gatsby'

const UseCase = () => {
  const [swiperInit, setSwiperInit] = useState({
    ready: false,
    Swiper: null,
    SwiperSlide: null,
    Navigation: null,
    FreeMode: null,
    Mousewheel: null
  })

  useEffect(() => {
    const initSwiper = async () => {
      if (typeof window !== 'undefined') {
        try {
          // 使用完整路徑引入
          const swiperCore = await import('swiper/react')
          const swiperModules = await import('swiper/modules')

          // 引入樣式
          await import('swiper/css')
          await import('swiper/css/navigation')

          setSwiperInit({
            ready: true,
            Swiper: swiperCore.Swiper,
            SwiperSlide: swiperCore.SwiperSlide,
            Navigation: swiperModules.Navigation,
            FreeMode: swiperModules.FreeMode,
            Mousewheel: swiperModules.Mousewheel
          })
        } catch (error) {
          console.error('Swiper initialization failed:', error)
        }
      }
    }

    initSwiper()
  }, [])

  if (!swiperInit.ready) {
    // 載入中的畫面
    return (
      <div className="relative flex items-center justify-center px-9 md:px-[86px]">
        <h2 className="h2-block-title text-center text-black-100">Use Case</h2>
      </div>
    )
  }

  const { Swiper, SwiperSlide, Navigation, FreeMode, Mousewheel } = swiperInit

  return (
    <div>
      <div className="relative flex items-center justify-center px-9 md:px-[86px]">
        <h2 className="h2-block-title text-center text-black-100">Use Case</h2>
        <div className="absolute hidden md:flex md:items-center md:justify-center md:gap-5 md:right-[86px] text-white h3-card-title z-10">
          <button className="prev-button h-9 disabled:opacity-10">
            <i className="flex justify-center material-icons-outlined overflow-hidden w-5 h-9 text-[36px] text-black-100 hover:translate-x-[-0.5rem] duration-200 transition-all">
              chevron_left
            </i>
          </button>
          <button className="next-button h-9 disabled:opacity-10">
            <i className="flex justify-center material-icons-outlined overflow-hidden w-5 h-9 text-[36px] text-black-100 hover:translate-x-2 duration-200 transition-all">
              chevron_right
            </i>
          </button>
        </div>
      </div>

      <div className="mt-10 flex">
        <div className="block shrink-0 w-[66px] h-[400px] rounded-lg bg-transparent pointer-events-none" />
        <Swiper
          modules={[Navigation, FreeMode, Mousewheel]}
          spaceBetween={20}
          slidesPerView="auto"
          navigation={{
            enabled: true,
            prevEl: '.prev-button',
            nextEl: '.next-button',
            disabledClass: 'disabled'
          }}
          className="!px-10 lg:!pl-[86px] lg:!pr-6 w-full max-h-[400px]"
          observer={true}
          observeParents={true}
          mousewheel={{
            enabled: true,
            sensitivity: 1,
            forceToAxis: true
          }}
          freeMode={{
            enabled: true,
            momentumBounce: true,
            sticky: true
          }}
        >
          {cases.map((item, index) => (
            <SwiperSlide key={`${item.category}${index}`} className="group !w-auto h-fit">
              <Link to={item.url}>
                <div className="relative w-[300px] h-[400px] flex flex-col bg-no-repeat bg-cover bg-center overflow-hidden rounded-xl">
                  <div
                    style={{ backgroundImage: `url(${item.picture})` }}
                    className="absolute w-[300px] h-[400px] top-0 left-0 bg-no-repeat bg-cover bg-center group-hover:scale-110 transition-all duration-200 ease-linear"
                  />
                  <div className="static z-[1] pt-2.5">
                    <p className="h5-subtext-bold text-white px-5">{item.category}</p>
                  </div>
                  <div className="absolute bg-black-100 opacity-90 bottom-0 left-0 w-[300px] h-[93px] rounded-b-xl px-5 py-3">
                    <div className="flex items-start justify-between">
                      <p className="h4-content-text text-white group-hover:text-blue-100 duration-200 ease-linear">
                        {item.purpose}
                      </p>
                      <Icon
                        fontSize="30px"
                        name="arrow_forward"
                        className="text-white group-hover:text-blue-100 duration-200 ease-linear"
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default UseCase
