import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SimpleAccordion from 'components/accordion/simple'
import ClickFadeCarousel from 'components/carousel/clickFade'

const ContentCarousel = ({ listItem }) => {
  const [accordionIndex, setAccordionIndex] = useState(0)
  const clickAccordion = (index) => {
    setAccordionIndex(index)
  }

  return (
    <div className="flex flex-col md:flex-row gap-10 justify-between">
      <div className="flex flex-1 justify-center">
        <div className="flex-1">
          <SimpleAccordion
            clickHandler={(index) => clickAccordion(index)}
            defaultExpandIndex={0}
            list={listItem}
            borderColor="#B9B9B9"
            iconStyle="plus"
            iconClassName="text-black-90"
            h3ClassName="text-black-90 h4-content-text-bold py-1.5"
            spanClassName="text-black-70 h5-subtext-medium block pb-3"
            cannotBeShrinked
          />
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        <ClickFadeCarousel activeIndex={accordionIndex} slides={listItem} />
      </div>
    </div>
  )
}

ContentCarousel.propTypes = {
  listItem: PropTypes.array
}

export default ContentCarousel
